<template lang="html">
  <div class="">
    <div class="row w-full" v-if="destino==20">
      <div class="col-md-4">
          <span class="span-placeholder">Valor del terreno</span>
          <div class="alinear">
          <input id="demoTextBox"  class="inputDataText" placeholder="$X,XXX,XXX.XX" v-validate="'max:11', 'min:6'" v-model="valores.valorTerreno"  type="text" @change="calculoValorProyectado(); updateValores(); formateaValorTerreno()" v-on:keyup="validaValorTerreno()" v-on:keypress="isNumber($event);  "/>
          <div class="porcentaje">{{valores.porcentajeValor}}</div>
          </div>
          <span 
            class="text-danger span-placeholder d-block" 
            v-if="isDecimalVA">Se recomienda trabajar números enteros.</span>
      </div>
      <div class="col-md-4">
        <span class="span-placeholder">Proyecto</span>
        <div class="alinear">
        <!--<div class="inputwrapper" data-required="">
        </div>-->
        <input id="demoTextBox"  class="inputDataText" placeholder="$X,XXX,XXX.XX" v-validate="'max:13'" v-model="valores.proyecto" name="credito" @change=" calculoValorProyectado(); updateValores(); formateaProyecto()" v-on:keyup="validaProyecto()" v-on:keypress="isNumber($event)" />
        <div class="porcentaje">{{valores.porcentajeProyecto}}</div>
        </div>
      </div>

    </div>
    <div class="row w-full" v-if="destino==20">
      <div class="col-md-4">
          <span class="span-placeholder">Valor proyectado</span>
          <div class="inputwrapper" data-required="MXM">
          <input id="demoTextBox"  class="inputDataText" placeholder="$X,XXX,XXX.XX" v-model="valores.valor"  name="username" disabled   type="text"  />
          </div>
      </div>
      <div class="col-md-4">
        <span class="span-placeholder">Monto de crédito</span>
        <div class="alinear">
        <input id="demoTextBox"  class="inputDataText" placeholder="$X,XXX,XXX.XX" v-model="valores.credito" name="credito" @change="calculoValorProyectado(); updateValores(); formateacredito()" v-on:keyup="validacredito()" v-on:keypress="isNumber($event)" />
        <div class="porcentaje">{{valores.porcentajeCredito}}</div>
        </div>
        <span class="text-danger text-sm" v-show="validacion">Debe ser máximo el {{ porcentajeMaximo }}% del valor de la propiedad</span>
        <span 
          class="text-danger span-placeholder d-block" 
          v-if="isDecimalMA">Se recomienda trabajar números enteros.</span>
      </div>

    </div>
    <div class="row w-full" v-if="destino!==20">
      <div class="col-md-4">
          <span class="span-placeholder">Valor de vivienda (valor proyectado) </span>
          <div class="inputwrapper" data-required="MXM">
          <input id="demoTextBox"  class="inputDataText" placeholder="$X,XXX,XXX.XX" v-model="valores.valor" name="username" @change="updateValores(); formateaValor(); calculaProrcentajeCre()" v-on:keyup="validaValor()" v-on:keypress="isNumber($event)"/>
          </div>
          <span 
            class="text-danger span-placeholder d-block" 
            v-if="isDecimalVB">Se recomienda trabajar números enteros.</span>
      </div>
      <div class="col-md-4">
        <span class="span-placeholder">Monto de crédito</span>
        <div class="alinear">
        <input id="demoTextBox"  class="inputDataText" placeholder="$X,XXX,XXX.XX" v-model="valores.credito" name="credito" @change="updateValores(); formateacredito(); calculaProrcentajeCre()" v-on:keyup="validacredito()"  v-on:keypress="isNumber($event)"/>
        <div class="porcentaje">{{valores.porcentajeCredito}}</div>
        </div>
        <span class="text-danger span-placeholder" v-show="validacion">Debe ser máximo el {{ porcentajeMaximo }}% del valor de la propiedad</span>
        <span 
          class="text-danger span-placeholder d-block" 
          v-if="isDecimalMA">Se recomienda trabajar números enteros.</span>
      </div>

    </div>
    <div class="row w-full">
      <div class="col-md-4">
        <span class="span-placeholder">Plazos a cotizar</span>
        <v-select class="" v-model="valores.plazosACotizar" multiple :closeOnSelect="false" :options="plazos" :dir="$vs.rtl ? 'rtl' : 'ltr'" @input="updateValores(), buscaBancos()"/>
      </div>
      <div class="col-md-4">
        <span class="span-placeholder">Esquemas de pago</span>
        <v-select v-model="valores.esquemasACotizar" multiple :closeOnSelect="false" :options="esquemas" :dir="$vs.rtl ? 'rtl' : 'ltr'" @input="updateValores(), buscaBancos()"/>
      </div>
      <!--<div class="vx-col lg:w-1/4 sm:w-full">
         <span class="span-placeholder">Gastos notariales</span>
        <select name="gastosNotariales" id="gastosNotariales" v-model="valores.gastosNotarial" class="v-select vs--single  vs-input--input" @change="updateValores()">
              <option :value='tipo.value' v-for="tipo in gastosNotariales"  :key="">{{tipo.label}}</option>
        </select>
      </div>-->
    </div>
    <input class="vs-input" hidden   v-model="otro" name="otro" @change="updateValores()" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect,
  },
   props: {
    otro: {
      type: Number,
      required: false
    },
    destino: {
      type: Number,
      required: false
    },
    porcentajeMaximo: {
      type: Number,
      required: false
    }
   }, 
  data(){
		return{
      plazos:[
        { label: '20 Años',  value: '20' },
        { label: '25 Años',  value: '25' },
        { label: '24 Años',  value: '24' },
        { label: '23 Años',  value: '23' },
        { label: '22 Años',  value: '22' },
        { label: '21 Años',  value: '21' },
        { label: '19 Años',  value: '19' },
        { label: '18 Años',  value: '18' },
        { label: '17 Años',  value: '17' },
        { label: '16 Años',  value: '16' },
        { label: '15 Años',  value: '15' },
        { label: '14 Años',  value: '14' },
        { label: '13 Años',  value: '13' },
        { label: '12 Años',  value: '12' },
        { label: '11 Años',  value: '11' },
        { label: '10 Años',  value: '10' },
        { label: '9 Años',  value: '9' },
        { label: '8 Años',  value: '8' },
        { label: '7 Años',  value: '7' },
        { label: '6 Años',  value: '6' },
        { label: '5 Años',  value: '5' },
        { label: '4 Años',  value: '4' },
        { label: '3 Años',  value: '3' },
        { label: '2 Años',  value: '2' },
        { label: '1 Años',  value: '1' },
      ],
      esquemas:[
        { label: 'Pago Fijo',  value: '2' },
        { label: 'Pago creciente',  value: '1' },
      ],
      id:'',
      gastosNotariales:[{label : '1 %', value : '1'},
                        {label : '2 %', value : '2'},
                        {label : '3 %', value : '3'},
                        {label : '4 %', value : '4'},
                        {label : '5 %', value : '5'},
                        {label : '6 %', value : '6'},
                        {label : '7 %', value : '7'},
                        {label : '8 %', value : '8'},
                        {label : '9 %', value : '9'},
                        {label : '10 %', value : '10'}
      ],
      valores:{
        destino:5,
        valor:'',
        credito:'',
        esquemasACotizar:[{ label: 'Pago Fijo',  value: '2' }],
        plazosACotizar:[{ label: '20 Años',  value: '20' }],
        gastosNotarial:6,
        otro:0,
        valorTerreno:0,
        porcentajeValor:'0%',
        porcentajeProyecto:'0%',
        porcentajeCredito:'0%',
        proyecto:0
      },
      isDecimalVA: false,
      isDecimalMA: false,
      isDecimalVB: false
    }
  },
  mounted(){
    this.id=this.$route.params.id
    this.getApikey()
    if(this.id)this.dameCaso()

    if(this.$route.path == '/nuevaCotizacion' && localStorage.getItem('caso')){
      this.id=localStorage.getItem('caso')
      this.dameCaso()
    }
    this.updateValores()
  },
  watch: {
    /*valor: function(newValue) {
      this.valores.valor= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    credito: function(newValue) {
      this.valores.credito= newValue.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },*/
    otro:function(){
      if(this.otro > 0){
        this.updateValores()
      }
    },
    'valores.valorTerreno': function(newValue, oldValue) {
      if (typeof(newValue) == 'string' && newValue.includes(',')) {
        newValue = newValue.replaceAll(',', '');
      }
      let rgx = /^\d+\.\d{1,2}$/;
      if (rgx.test(newValue)) {
        // console.log( 'Tiene puntoVA' );
        this.isDecimalVA = true;
      } else {
        this.isDecimalVA = false;
      }
    },
    'valores.credito': function(newValue, oldValue) {
      if (typeof(newValue) == 'string' && newValue.includes(',')) {
        newValue = newValue.replaceAll(',', '');
      }
      let rgx = /^\d+\.\d{1,2}$/;
      if (rgx.test(newValue)) {
        // console.log( 'Tiene puntoMA' );
        this.isDecimalMA = true;
      } else {
        this.isDecimalMA = false;
      }
    },
    'valores.valor': function(newValue, oldValue) {
      if (typeof(newValue) == 'string' && newValue.includes(',')) {
        newValue = newValue.replaceAll(',', '');
      }
      let rgx = /^\d+\.\d{1,2}$/;
      if (rgx.test(newValue)) {
        // console.log( 'Tiene puntoMA' );
        this.isDecimalVB = true;
      } else {
        this.isDecimalVB = false;
      }
    }
  },
  computed:{
    validacion() {
      return this.valores.credito.toString().replace(/,/g,"") > this.valores.valor.toString().replace(/,/g,"") * 0.95
    },
    valor(){
      return this.valores.valor
    },
    credito(){
      return this.valores.credito
    }
    
  },
  methods:{
    // isDecimalNumber(type) {

    //   console.log( 'isDecimalNumber', type );

    //   let rgx = /^\d+\.\d{1,2}$/;

    //   switch (type) {
    //     case 'va':
    //       if (rgx.test(this.valores.valor)) {
    //         console.log( 'Tiene puntoVA' );
    //         this.isDecimalVA = true;
    //       } else {
    //         this.isDecimalVA = false;
    //       }
    //       break;
    //     case 'ma':
    //       if (rgx.test(this.valores.credito)) {
    //         console.log( 'Tiene puntoMA' );
    //         this.isDecimalMA = true;
    //       } else {
    //         this.isDecimalMA = false;
    //       }
    //       break;
    //     case 'vb':
    //       if (rgx.test(this.valores.valorTerreno)) {
    //         console.log( 'Tiene puntoVB' );
    //         this.isDecimalVB = true;
    //       } else {
    //         this.isDecimalVB = false;
    //       }
    //       break;
    //     case 'mb':
    //       if (rgx.test(this.valores.credito)) {
    //         console.log( 'Tiene puntoMB' );
    //         this.isDecimalMB = true;
    //       } else {
    //         this.isDecimalMB = false;
    //       }
    //       break;
    //   }
    // },
    calculaProrcentajeCre(){
      this.valores.porcentajeCredito= (this.valores.credito.toString().replace(/,/g,"") / this.valores.valor.toString().replace(/,/g,"")) * 100
      this.valores.porcentajeCredito=this.valores.porcentajeCredito.toFixed(2)+'%'
    },
    validaValor() {
      this.valores.valor=this.valores.valor.replace(/,/g,"")
      const regex = /^(\d{1,8})(\.\d{0,2})?$/  ///^[0-9]{1,10}\.?[0-9]{0,2}$/ ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      const matches = regex.test(this.valores.valor);
      if(matches===true){
      this.valores.valor= this.valores.valor// .toString().replace(exp,rep);
      }else{
        this.valores.valor=this.valores.valor.replace(/.$/, '')
      }
    },
    formateaValor(){
     
      this.valores.valor = this.valores.valor.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

      if(this.valores.valor=='' || this.valores.valor==0 || this.valores.valor.length > 13){
        this.valores.valor = 100000
      }     
      if(this.valores.valor.length < 6 ){
        this.valores.valor = 100000 
      }
      
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;  
      const rep = '$1,';
      this.valores.valor= this.valores.valor.toString().replace(exp,rep);
    },
    validaProyecto() {
      this.valores.proyecto=this.valores.proyecto.replace(/,/g,"")
      const regex = /^(\d{1,8})(\.\d{0,2})?$/  ///^[0-9]{1,10}\.?[0-9]{0,2}$/ ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      const matches = regex.test(this.valores.proyecto);
      if(matches===true){
      this.valores.proyecto= this.valores.proyecto// .toString().replace(exp,rep);
      }else{
        this.valores.proyecto=this.valores.proyecto.replace(/.$/, '')
      }
    },
    formateaProyecto(){

      this.valores.proyecto = this.valores.proyecto.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

      if(this.valores.proyecto =='' || this.valores.proyecto == 0 || this.valores.proyecto.length > 13){
        this.valores.proyecto=700000
      }
      if(this.valores.proyecto==''){
        this.valores.proyecto=0
      }
    
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;  
      const rep = '$1,';
      this.valores.proyecto= this.valores.proyecto.toString().replace(exp,rep);
    },
    validaValorTerreno() {
      this.valores.valorTerreno=this.valores.valorTerreno.replace(/,/g,"")
      const regex = /^(\d{1,8})(\.\d{0,2})?$/  ///^[0-9]{1,10}\.?[0-9]{0,2}$/ ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      const matches = regex.test(this.valores.valorTerreno);
      if(matches===true){
      this.valores.valorTerreno= this.valores.valorTerreno// .toString().replace(exp,rep);
      }else{
        this.valores.valorTerreno=this.valores.valorTerreno.replace(/.$/, '')
      }
    },
    formateaValorTerreno(){
      this.valores.valorTerreno = this.valores.valorTerreno.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

      if(this.valores.valorTerreno=='' || this.valores.valorTerreno==0){
        this.valores.valorTerreno = 100000
      }     
      if(this.valores.valorTerreno.length < 6 || this.valores.valorTerreno.length > 13){
        this.valores.valorTerreno = 100000 
      }

      const exp = /(\d)(?=(\d{3})+(?!\d))/g;  
      const rep = '$1,';
      this.valores.valorTerreno= this.valores.valorTerreno.toString().replace(exp,rep);
    },
    buscaBancos(){
      this.valores.otro=this.otro
      this.$emit('buscaBancos',this.valores)
    }, 
    validacredito() {
      this.valores.credito=this.valores.credito.replace(/,/g,"")
      const regex = /^(\d{1,8})(\.\d{0,2})?$/  
      const matches = regex.test(this.valores.credito);
      if(matches===true){
      this.valores.credito= this.valores.credito
      }else{
        this.valores.credito=this.valores.credito.replace(/.$/, '')
      }
    },
    formateacredito(){
      this.valores.credito = this.valores.credito.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

      if(this.valores.credito =='' || this.valores.credito == 0 || this.valores.credito.length > 13){
        this.valores.credito=700000
      }

      const exp = /(\d)(?=(\d{3})+(?!\d))/g;  
      const rep = '$1,';
      this.valores.credito= this.valores.credito.toString().replace(exp,rep);
    },
    quitarComas(){
      this.valores.valorTerreno=this.valores.valorTerreno.toString().replace(/,/g,"")
      this.valores.proyecto=this.valores.proyecto.toString().replace(/,/g,"")
      this.valores.credito=this.valores.credito.toString().replace(/,/g,"")
    },
    calculoValorProyectado(){
      this.valores.valor = parseFloat(this.valores.valorTerreno.toString().replace(/,/g,""))  +parseFloat(this.valores.proyecto.toString().replace(/,/g,""))
      this.valores.porcentajeValor = (this.valores.valorTerreno.toString().replace(/,/g,"") / this.valores.valor) *100
      this.valores.porcentajeProyecto=(this.valores.proyecto.toString().replace(/,/g,"")/this.valores.valor)* 100
      this.valores.porcentajeCredito= (this.valores.credito.toString().replace(/,/g,"") / this.valores.valor) * 100

      this.valores.valor=this.valores.valor.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      this.valores.porcentajeValor=this.valores.porcentajeValor.toFixed(2)+'%'
      this.valores.porcentajeProyecto=this.valores.porcentajeProyecto.toFixed(2)+'%'
      this.valores.porcentajeCredito=this.valores.porcentajeCredito.toFixed(2)+'%'
      
      
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    updateValores(){
      //alert('updateTerreno'+this.valores.valor)
      this.valores.otro=this.otro
      this.$emit('updateValores',this.valores)
    },
    dameCaso(){
      
      let self= this
      var objRequestDameCaso = {
        strApiKey: this.key,
        strMetodo: 'DameCaso',
        objCaso: {
         Id: this.id
        }
      }
      this.$axios.post('/',objRequestDameCaso,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            let cotizacion=response.data.objContenido;
            // this.valores.destino=cotizacion.Destino

            this.valores.plazosACotizar=[]
            this.valores.esquemasACotizar=[]
            this.valores.valor=(cotizacion.ValorInmueble).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
             // alert('dame Caso'+this.valores.valor)
            this.valores.credito=(cotizacion.MontoSolicitado).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            for (var i = 0; i < cotizacion.Plazos.length; i++) {
              this.valores.plazosACotizar.push(cotizacion.Plazos[i]+' Años')
            }
            for (var i = 0; i < cotizacion.Esquemas.length; i++) {
              if (cotizacion.Esquemas[i] == 2) {
                this.valores.esquemasACotizar.push({ label: 'Pago Fijo',  value: '2' })
              }  else if (cotizacion.Esquemas[i] == 1) {
                this.valores.esquemasACotizar.push({ label: 'Pago creciente',  value: '1' })
              }
            }
            this.valores.gastosNotarial=cotizacion.GastoNotarial
            this.valores.destino=cotizacion.Destino
            if(this.valores.destino == 20){
              this.valores.proyecto=(cotizacion.ValorProyecto).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.valores.valorTerreno=(cotizacion.ValorTerreno).toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              this.calculoValorProyectado()
            }
            this.calculaProrcentajeCre()
                this.updateValores()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en DameCaso',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    }
  }
  }
</script>

<style lang="css" scoped>
</style>